body {
    padding: 30px;
}
.bg-responsive {
    width: 100%;
    height: 500px;
}

/* XS */
/* max-width: $screen-xs-max */

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

/* SM */
/* min-width: $screen-sm-min) and (max-width: $screen-sm-max */

@media (min-width: 480px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

/* MD */
/* min-width: $screen-md-min) and (max-width: $screen-md-max */

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

/* LG */
/* min-width: $screen-lg-min */

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}